<template>
    <div class="hero">
        <div
            class="wistia_embed seo=true fitStrategy=cover"
            :class="$t('home.newHomeSection.videoId')"
            style="height: 100%; position: relative; width: 100%"
        >
            &nbsp;
        </div>

        <div class="hero__overlay"></div>
        <div class="hero__content__wrapper align-items-center container">
            <PinFormContainer :class="`hero__content ${!isError ? 'slide-up-transition' : ''}`" new-pin-form />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import PinFormContainer from "Modules/Home/components/Home/ContainerHero/PinFormContainer.vue";

export default defineComponent({
    name: "HeroV2",
    components: { PinFormContainer },
    props: {
        isError: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
});
</script>

<style scoped lang="scss">
.hero {
    font-family: "BMWTypeNext Pro Regular", sans-serif;
    height: 520px;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down("sm") {
        height: 580px;
    }

    .video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width) */
        height: 0;
        overflow: hidden;

        .video {
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            height: 100% !important;
        }
    }

    .hero__video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
    }
    .hero__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0) 36.03%,
            rgba(0, 0, 0, 0.38) 55.01%,
            rgba(0, 0, 0, 0.8) 99.96%
        );

        @include media-breakpoint-down("sm") {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.48) 40%, rgba(0, 0, 0, 0) 100%);
        }

        z-index: 0;
    }
    .hero__content__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        z-index: 1;

        @include media-breakpoint-down("sm") {
            top: 70%;
            display: flex;
            justify-content: center;
        }

        .hero__content {
            max-width: 303px;
            h1 {
                text-transform: uppercase;
                font-size: px2rem(36);
                margin-bottom: 20px;
                line-height: normal;

                @include media-breakpoint-down("sm") {
                    font-size: px2rem(32);
                }
            }
            p {
                font-size: 1.5rem;
            }
        }
    }
}
</style>
